export const getUrlWithId = (
  url: string,
  afterPath: string,
  id: number,
): string | undefined => {
  const urlParts = url.split("/");

  if (urlParts.length > 1) {
    const pathIdx = urlParts.findIndex((part) => part === afterPath);

    if (pathIdx >= 0) {
      urlParts.splice(pathIdx + 1, 0, id.toString());
      return urlParts.join("/");
    }
  }
};
